import { render, staticRenderFns } from "./call-log-table.vue?vue&type=template&id=0ad7bd33&scoped=true"
import script from "./call-log-table.vue?vue&type=script&lang=js"
export * from "./call-log-table.vue?vue&type=script&lang=js"
import style0 from "./call-log-table.vue?vue&type=style&index=0&id=0ad7bd33&prod&scoped=true&lang=css"
import style1 from "./call-log-table.vue?vue&type=style&index=1&id=0ad7bd33&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ad7bd33",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TableFilters: require('/codebuild/output/src2108204121/src/web/components/table-filters.vue').default,TableActions: require('/codebuild/output/src2108204121/src/web/components/table-actions.vue').default,ConfirmModal: require('/codebuild/output/src2108204121/src/web/components/confirm-modal.vue').default,CallLogBulkOperationModal: require('/codebuild/output/src2108204121/src/web/components/call-log/call-log-bulk-operation-modal.vue').default})
